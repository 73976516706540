import { AppKeyPixelsAdmin } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'pixels-admin-client';

export const PixelsAdminProjectMeta: ProjectMeta = {
  id: AppKeyPixelsAdmin,
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod],
  noApi: true,
  port: 8106,
  apiName: 'pixels-admin-api',
  apiPort: 5006,
  appId: 'io.pixelteam.admin',
  appStoreUrl: '',
  appName: 'PixelAdmin',
  appSchemeName: 'PixelAdmin',
  testTargetUrl: 'https://test-admin.pixelteam.io',
  devTargetUrl: 'https://dev-admin.pixelteam.io',
  prodApi: 'https://admin-api.pixelteam.io',
  devApi: 'https://test-admin-api.pixelteam.io',
  firebaseId: 'pixels-op',
  testHostname: 'test-admin',
  clientSentryDsn: 'https://a92795d499709f267b52b483b29df94b@o190729.ingest.sentry.io/4506093098565632',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11302/deploy',
    targets: ['prod-01'],
    port: 8700,
    apiUrl: 'https://admin-api.pixelteam.io',
    kakaoAppKey: '',
    googleReversedId: '',
    iosEncodingAppId: '',
    firebaseConfig: {
      apiKey: 'AIzaSyABz6p83e0tKU7uxB-wHtT8ZnhFwA6wcis',
      authDomain: 'pixels-op.firebaseapp.com',
      projectId: 'pixels-op',
      storageBucket: 'pixels-op.appspot.com',
      messagingSenderId: '253893798669',
      appId: '1:253893798669:web:7de7dd72acce389e629e44'
    }
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11289/deploy',
    apiUrl: 'https://dev-admin-api.pixelteam.io',
    port: 8701,
    kakaoAppKey: '',
    googleReversedId: '',
    iosEncodingAppId: '',
    firebaseConfig: {
      apiKey: 'AIzaSyABz6p83e0tKU7uxB-wHtT8ZnhFwA6wcis',
      authDomain: 'pixels-op.firebaseapp.com',
      projectId: 'pixels-op',
      storageBucket: 'pixels-op.appspot.com',
      messagingSenderId: '253893798669',
      appId: '1:253893798669:web:7de7dd72acce389e629e44'
    }
  },
  test: {
    apiUrl: 'https://test-admin-api.pixelteam.io',
    port: 8701,
    kakaoAppKey: '',
    googleReversedId: '',
    iosEncodingAppId: '',
    firebaseConfig: {
      apiKey: 'AIzaSyABz6p83e0tKU7uxB-wHtT8ZnhFwA6wcis',
      authDomain: 'pixels-op.firebaseapp.com',
      projectId: 'pixels-op',
      storageBucket: 'pixels-op.appspot.com',
      messagingSenderId: '253893798669',
      appId: '1:253893798669:web:7de7dd72acce389e629e44'
    }
  },
  consoleLogo: `
██████╗ ██╗██╗  ██╗███████╗██╗     ███████╗     █████╗ ██████╗ ███╗   ███╗██╗███╗   ██╗
██╔══██╗██║╚██╗██╔╝██╔════╝██║     ██╔════╝    ██╔══██╗██╔══██╗████╗ ████║██║████╗  ██║
██████╔╝██║ ╚███╔╝ █████╗  ██║     ███████╗    ███████║██║  ██║██╔████╔██║██║██╔██╗ ██║
██╔═══╝ ██║ ██╔██╗ ██╔══╝  ██║     ╚════██║    ██╔══██║██║  ██║██║╚██╔╝██║██║██║╚██╗██║
██║     ██║██╔╝ ██╗███████╗███████╗███████║    ██║  ██║██████╔╝██║ ╚═╝ ██║██║██║ ╚████║
╚═╝     ╚═╝╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝    ╚═╝  ╚═╝╚═════╝ ╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝
`
};
